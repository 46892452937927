<template>
    <div>
        <div class="grid-content bg-purple borderstyle" style="margin-bottom: 5px; float: right">
            <el-button type="primary" @click="edituserinfo()">新增</el-button>
        </div>
        <!-- {{ this.$props.userdata}} -->
        <div class="pitable">
            <el-table :data="this.$props.userdata['data']" :row-class-name="tabRowClassName" style="width: 100%"
                :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                <el-table-column label="序号" type="index"> </el-table-column>
                <el-table-column label="用户名称" prop="username" align="center">
                    <template slot-scope="{ row }">
                        <router-link :to="gotoStep(row)" class="linkto">
                            <span>{{ row.username }}</span>
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="type_name" label="角色" align="center">
                </el-table-column>
                <el-table-column prop="company" label="企业名称" align="center">
                </el-table-column>
                <el-table-column label="用户权限" align="center">
                    <template slot-scope="{ row }">
                        <div v-for="(item, index) in row.role_type_name" :key="index">
                            <el-tag effect="dark" size="mini" style="margin-left: 5px" v-if="item == '初审'">{{ item
                                }}</el-tag>
                            <el-tag effect="dark" size="mini" type="success" style="margin-left: 5px"
                                v-if="item == '复审'">{{
                                    item }}</el-tag>
                            <el-tag effect="dark" size="mini" type="danger" style="margin-left: 5px"
                                v-if="item == '终审'">{{ item
                                }}</el-tag>
                        </div>
                    </template>

                </el-table-column>
                <el-table-column prop="telephone" label="联系方式" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100" align="center">
                    <template slot-scope="{ row }">
                        <el-button type="text" @click="edituserinfo(row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="pageTotal">

            <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="this.$props.userdata['current_page']" :page-sizes="[10, 20, 30, 40]"
                    :page-size="Number(this.$props.userdata['per_page'])" layout="total,sizes, prev, pager, next"
                    :total="this.$props.userdata['total']">
                </el-pagination>
            </div>
        </div>

        <el-dialog :visible.sync="editDialogVisible" :title="public_title" :close-on-click-modal="false">
            <el-form :model="editForm" ref="editForm" :rules="roleRules">
                <el-form-item label="登录账户" prop="username"
                    :rules="[{ required: true, message: '请输入用户账号', trigger: 'blur' }]">
                    <el-input v-model="editForm.username"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="password"
                    :rules="[{ required: true, message: '请输入用户密码', trigger: 'blur' }]">
                    <el-input placeholder="请输入密码" v-model="editForm.password" show-password></el-input>
                </el-form-item>
                <el-form-item label="用户姓名" prop="username"
                    :rules="[{ required: true, message: '请输入用户姓名', trigger: 'blur' }]">
                    <el-input v-model="editForm.username"></el-input>
                </el-form-item>

                <el-form-item label="角色" prop="type" :rules="[{ required: true, message: '请选择角色', trigger: 'blur' }]">
                    <el-select v-model="editForm.type" style="width: 100%" placeholder="请选择">
                        <el-option v-for="item in role_list" :key="item.value" :label="item.label" :value="item.value">

                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户权限" prop="role_type"
                    :rules="[{ required: true, message: '请选择用户权限', trigger: 'change' }]">
                    <el-select multiple v-model="editForm.role_type" style="width: 100%" placeholder="请选择">
                        <el-option v-for="item in role_type" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系方式" prop="telephone">
                    <el-input v-model.number="editForm.telephone"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAdd()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getUserList, addUser, editUser, userInfoDetail } from "@api/user";
import { checkPhone } from '@utils/filters';
export default {
    props: {
        userdata: Object || Array || null,//用户数据
    },
    data() {
        return {
            //选中的表格数据
            form: {},
            page: 0,
            limit: 10,
            totalCount: 100,
            totalPage: 1,
            userlimit: 10,
            role_type: [
                {
                    value: "1",
                    label: "初审",
                },
                {
                    value: "2",
                    label: "复审",
                },
                {
                    value: "3",
                    label: "终审",
                },
            ],
            userpage: 1,
            usertotalCount: 0,
            usertotalPage: 1,
            userstabledata: [],
            token: "",
            telephone: "",
            name: "",
            address: "",
            activeName: "first",
            editDialogVisible: false,
            addDialogVisible: false,
            editForm: {
                id: undefined,
                username: "",
                role_id: 4,
                role_type: [

                ],
                telephone: "",
                password: "",
                type: []
            },
            role_list: [
                {
                    value: 1,
                    label: "管理员",
                },
                {
                    value: 2,
                    label: "专家",
                },
                {
                    value: 3,
                    label: "企业",
                },
            ],
            roleRules: {
                telephone: [
                    { required: true, message: "请输入正确手机号", trigger: "blur" },
                    { validator: checkPhone, trigger: 'blur' }
                ],
            },
            editIndex: -1,
            activeTitle: "users",
            start_time: "",
            timeRange: [],
            isModify: false,
            getId: null,
            isDisabled: false,
            public_title: "新增用户",
        };
    },
    mounted() {
        this.getToken();

    },
    methods: {
        addUser() {
            this.addDialogVisible = true;
        },
        //编辑用户信息
        edituserinfo(row) {
            if (row === undefined) {
                this.public_title = '新增用户'
                this.editForm.username = ""
                this.editForm.password = ""
                this.editForm.role_id = ""
                this.editForm.role_type = []
                this.editForm.telephone = ""
            } else if (row != undefined) {
                this.public_title = '修改用户'
                userInfoDetail({ id: row.id, token: this.token }).then((res) => {
                    this.editForm.username = res.data.username
                    this.editForm.password = res.data.password
                    this.editForm.type = res.data.type
                    this.editForm.role_type = res.data.role_type
                    this.editForm.telephone = res.data.telephone
                    this.editForm.id = res.data.id;
                });
            }
            this.editDialogVisible = true;
        },
        // 添加用户
        saveAdd() {
            this.editForm.token = this.token;          


            if (this.editForm.id === null || this.editForm.id === undefined) {
                //新增
                this.editForm.role_id = 1
                addUser(this.editForm).then((res) => {
                    console.log(res);
                    this.$message.success("添加成功");
                    this.editDialogVisible = false;
                    this.fetchUserList(4, 1);
                });
            } else if (this.editForm.id != '') {
                //修改
                editUser(this.editForm).then((res) => {
                    this.$message.success("修改成功");
                    this.editDialogVisible = false;
                    this.fetchUserList(4, 1);
                });
            }




        },

        tabRowClassName({ rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return "warning-row";
            }
        },

        getToken() {
            this.token = this.$store.state.token; //获取cookie字符串
            // this.fetchUserList(this.$props.userclass, this.$props.usertype);
        },

        //获取管理员用户列表
        fetchUserList(cl, ty) {
            let param = {
                token: this.token,
                page: this.page,
                limit: this.limit,
                class: cl,
                type: ty,
            };

            if (this.name) {
                param.name = this.name;
            }
            if (this.telephone) {
                param.telephone = this.telephone;
            }
            getUserList(param).then((res) => {
                this.userstabledata = res.data.data;
                this.limit = Number(res.data.per_page)
                this.page = res.data.current_page;
                this.totalCount = res.data.total;
                this.totalPage = res.data.last_page;
            });

        },
        // 分页
        handleSizeChange(page) {
            this.limit = page;
            // this.fetchUserList(4, 1);
        },
        userhandleSizeChange(page) {
            this.userlimit = page;
            // this.fetchUserList(4, 3);
        },

        handleCurrentChange(page) {
            this.page = page;
            // this.fetchUserList(4, 1);
        },
        userhandleCurrentChange(page) {
            this.userpage = page;
            // this.fetchUserList(4, 3);
        },
        // 查看详情
        gotoStep(row) {
            let url = "/user/accontimInfo?id=" + row.id + "&token=" + this.token;
            return url;
        },

    },
};
</script>

<style scoped>
/deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
}

/deep/.el-button--primary {
    background-color: #3082e2;
    border-color: #3082e2;
}

/deep/.el-button--primary:hover {
    background-color: #409eff;
    border-color: #409eff;
}

/deep/.el-input__suffix {
    right: 43px;
    transition: all 0.3s;
    border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
    background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
    min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
    border-bottom: 0;
}

/deep/.el-form-item__label {
    font-family: Adobe Heiti Std;
    color: #2d2d2d;
}

/deep/ .litop .el-input {
    width: 245px;
}

/deep/ .litop .el-input__inner {
    width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
    font-size: 16px;
}

/deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
}

.el-input__inner {
    position: relative;
}

.el-color-picker {
    position: absolute;
    right: 0;
}

.pitable {
    /*height: 60vh;*/
}

.litop {
    margin-top: 20px;
}

.litop span {
    color: #3086fb;
    margin-left: 10px;
    line-height: 40px;
}

.pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 38px 0;
}

.prbtn {
    display: flex;
    flex-direction: row-reverse;
}

.linkto {
    text-decoration: none;
    color: #3082e2;
}

.linkto>span:hover {
    color: #84b5ec;
}

/* 新改 */
.zhidingsearch {
    background-color: #ffffff;
    /* padding: 1px 15px; */
    margin: 20px 0 10px 0;
}

.piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #eeeeee;
}

.surebtn {
    margin-left: 30px;
}

.delete_text {
    color: #f51a1a;
}

.timearea {
    display: flex;
}

.timearea .current_label {
    padding: 10px 11px;
    font-size: 14px;
}
</style>