<template>
  <div>
    <div class="pititle">
      <div class="piconetn">
        <el-tabs v-model="activeTitle" @tab-click="handleMenu">
          <el-tab-pane label="用户管理" name="users">
            <div class="piconetn">
              <div class="zhidingsearch">
                <el-form>
                  <div class="litop">
                    <el-row>
                      <el-col :span="7">
                        <div class="grid-content bg-purple">
                          <el-form-item label="用户名称">
                            <el-input v-model="name" placeholder="请输入您的用户名称" @keyup.enter.native="search()"></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                          <el-form-item label="联系方式">
                            <el-input v-model="telephone" placeholder="请输入您的联系方式"
                              @keyup.enter.native="search()"></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <el-col :span="4">
                        <div class="grid-content bg-purple borderstyle">
                          <el-button type="primary" @click="search">查询</el-button>
                          <el-button type="info" @click="qingkong">清空</el-button>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-form>
              </div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="管理员用户" name="first">
                  <usersList :userdata="userstabledata" ></usersList>
                </el-tab-pane>
                <el-tab-pane label="企业用户" name="second">
                  <usersList  :userdata="userstabledata"></usersList>
                </el-tab-pane>
                <el-tab-pane label="专家用户" name="expert">
                  <usersList :userdata="userstabledata"></usersList>
                </el-tab-pane>
              </el-tabs>

            </div>
          </el-tab-pane>
          <el-tab-pane label="状态管理" name="status">
            <div style="margin-bottom: 5px; float: right">
              <el-button type="primary" @click="publicStatus">新增</el-button>
            </div>
            <div class="pitable">
              <el-table :data="statusTable" :row-class-name="tabRowClassName" style="width: 100%"
                :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                <el-table-column prop="number" label="代号"> </el-table-column>
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column prop="rgb" label="颜色" align="center">
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="{ row }">
                    <el-button type="text" size="small" @click="publicStatus(row)">编辑</el-button>
                    <el-button type="text" class="delete_text" @click="delStatus(row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="pageTotal">
              <div class="block">
                <el-pagination background @size-change="userhandleStatus" @current-change="statusCurrentChange"
                  :current-page="statuspage" layout="prev, pager, next" :total="statustotal" :page-size="statuslimit">
                </el-pagination>
              </div>
            </div>
            <!-- 表格 -->
            <el-dialog :visible.sync="statusDialogVisible">
              <el-form :model="statusForm" ref="statusForm">
                <el-form-item label="代号">
                  <el-input v-model="statusForm.number" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="名称">
                  <el-input v-model="statusForm.name"></el-input>
                </el-form-item>
                <el-form-item label="颜色">
                  <div>
                    <el-input v-model="statusForm.rgb"></el-input>
                    <el-color-picker v-model="statusForm.rgb"></el-color-picker>
                  </div>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="canCel">取 消</el-button>
                <el-button type="primary" @click="saveStatus">确 定</el-button>
              </div>
            </el-dialog>
          </el-tab-pane>
          <el-tab-pane label="标签管理" name="labelmanage">
            <div class="grid-content bg-purple borderstyle" style="margin-bottom: 5px; float: right">
              <el-button type="primary" @click="editlabel()">新增</el-button>
            </div>
            <div class="pitable">
              <el-table :data="labelData" :row-class-name="tabRowClassName" style="width: 100%"
                :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                <el-table-column label="序号" type="index"> </el-table-column>
                <el-table-column label="标签名称" prop="name" align="center">
                  <template slot-scope="{ row }">
                    <router-link :to="gotoStep(row)" class="linkto">
                      <span>{{ row.name }}</span>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180px" align="center">
                  <template slot-scope="{ row }">
                    <el-button type="text" @click="editlabel(row)">编辑</el-button>
                    <el-button type="danger" @click="delLabel(row.id)" size="mini" icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="pageTotal">
              <div class="block">
                <el-pagination background @size-change="handleTagSizeChange" @current-change="handleTagCurrentChange"
                  :current-page="lapage" layout="prev, pager, next" :total="latotalCount" :page-size="limit">
                </el-pagination>
              </div>
            </div>
            <el-dialog :visible.sync="label_show" :title="label_title" width="30%">
              <el-form>
                <el-form-item label="标签名称" class="label_input">
                  <el-input type="text" v-model="labelForm.name"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="label_show = false">取 消</el-button>
                <el-button type="primary" @click="label_sure">确 定</el-button>
              </span>
            </el-dialog>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import usersList from "./components/usersList.vue";
import { getUserList, addUser, editUser, userInfoDetail } from "@api/user";
import { statusAddList, statusList, statusEditData, statusDelData, standardTimeStore, standLabelAdd, queryLabels, labelEdit, labelDelete } from "@api/enterprise";
export default {
  components: { usersList },
  data() {
    var checkPhone = (rule, value, callback) => { // 手机号验证
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3456789]\d{9}$/
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      // 用户类型和分类
      usertype: 1,
      userclass: 4,
      //选中的表格数据
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      userlimit: 10,
      role_type: [
        {
          value: "1",
          label: "初审",
        },
        {
          value: "2",
          label: "复审",
        },
        {
          value: "3",
          label: "终审",
        },
      ],
      userpage: 1,
      usertotalCount: 0,
      usertotalPage: 1,
      userstabledata: [],
      token: "",
      telephone: "",
      name: "",
      address: "",
      activeName: "first",
      editDialogVisible: false,
      addDialogVisible: false,
      editForm: {
        id: undefined,
        username: "",
        role_id: 4,
        role_type: [],
        telephone: "",
        password: "",
        type: ""
      },
      role_list: [
        {
          id: 1,
          name: "管理员",
        },
        {
          id: 2,
          name: "专家",
        },
        {
          id: 3,
          name: "企业",
        },
      ],
      roleRules: {
        telephone: [
          { required: true, message: "请输入正确手机号", trigger: "blur" },
          { validator: checkPhone, trigger: 'blur' }
        ],
      },
      editIndex: -1,
      activeTitle: "users",
      statusTable: [],
      statusDialogVisible: false,
      statusForm: {
        number: "",
        name: "",
        rgb: "",
        colorName: "",
        role_type: ""
      },
      statuspage: 1,
      statustotal: 0,
      statuslimit: 10,
      start_time: "",
      timeRange: [],
      isModify: false,
      // getObj: null,
      getId: null,
      statuslabel: "开放申报",
      isDisabled: false,
      public_title: "新增用户",
      labelData: [],
      labelForm: {
        name: "",
      },
      label_show: false,
      lapage: 1,
      latotalCount: 0,
      latotalPage: 1,
      label_title: ''
    };
  },
  mounted() {
    this.getToken();

  },
  methods: {
    addUser() {
      this.addDialogVisible = true;
    },
    //编辑用户信息
    edituserinfo(row) {
      if (row === undefined) {
        this.public_title = '新增用户'
        this.editForm.username = ""
        this.editForm.password = ""
        this.editForm.role_id = ""
        this.editForm.role_type = []
        this.editForm.telephone = ""
      } else if (row != undefined) {
        this.public_title = '修改用户'
        userInfoDetail({ id: row.id, token: this.token }).then((res) => {
          this.editForm.username = res.data.username
          this.editForm.password = res.data.password
          this.editForm.type = res.data.type
          this.editForm.role_type = res.data.role_type
          this.editForm.telephone = res.data.telephone
          this.editForm.id = res.data.id;
        });
      }
      this.editDialogVisible = true;
    },

    saveAdd(formName) {
      this.editForm.token = this.token;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editForm.id === null || this.editForm.id === undefined) {
            //新增
            this.editForm.role_id = 4
            addUser(this.editForm).then((res) => {
              this.$message.success("添加成功");
              this.editDialogVisible = false;
              this.getUserList(4, 1);
            });
          } else if (this.editForm.id != '') {
            // debugger
            //修改
            editUser(this.editForm).then((res) => {
              this.$message.success("修改成功");
              this.editDialogVisible = false;
              this.getUserList(4, 1);
            });
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    handleMenu(tab, event) {
      this.activeTitle = tab.name
      if (this.activeTitle === 'status') {
        //查询状态
        this.page = 1
        this.queryStatusList()
      } else if (this.activeTitle === 'users') {
        //查询用户
        this.getUserList(4, 1);
      } else if (this.activeTitle == "labelmanage") {
        this.queryLabelData()
      }
    },
// 获取用户列表数据
    fetchUserList(cl, ty) {
            let param = {
                token: this.token,
                page: this.page,
                limit: this.limit,
                class: cl,
                type: ty,
            };

            if (this.name) {
                param.name = this.name;
            }
            if (this.telephone) {
                param.telephone = this.telephone;
            }
            getUserList(param).then((res) => {
                this.userstabledata = res.data;
                // this.limit = Number(res.data.per_page)
                // this.page = res.data.current_page;
                // this.totalCount = res.data.total;
                // this.totalPage = res.data.last_page;
            });

        },

    //点击tab事件
    handleClick(tab, event) {
      this.activeName = tab.name;
      if (this.activeName == "second") {
        this.usertype = 3;
        this.fetchUserList(4,3)
      } else if (this.activeName == "first") {
        this.usertype = 1;
        this.fetchUserList(4,1)
      } else {
        this.usertype = 2;
        this.fetchUserList(4,2)
      }
      this.page = 1
      this.userpage = 1
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },

    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
      this.fetchUserList(4, 1);
    },
    handleTagSizeChange(page) {
      this.limit = page
      this.queryLabelData()
    },
    handleTagCurrentChange(page) {
      this.lapage = page
      this.queryLabelData()
    },
    //确认提交标签
    label_sure() {
      if (this.labelForm.id === undefined) {
        let data = {
          token: this.$store.state.token,
          name: this.labelForm.name
        }
        standLabelAdd(data).then((rec) => {
          // console.log(rec);
          if (rec.code == 200) {
            this.$message({
              showClose: true,
              message: rec.message,
              type: "success",
            });
            this.queryLabelData()
            this.label_show = false
          }
        });
      } else if (this.labelForm.id != undefined) {
        let data = {
          token: this.$store.state.token,
          id: this.labelForm.id,
          name: this.labelForm.name
        }
        labelEdit(data).then((rec) => {
          if (rec.code == 200) {
            this.$message({
              showClose: true,
              message: rec.message,
              type: "success",
            });
            this.queryLabelData()
            this.label_show = false
          }
        });
      }
    },
    //查询标签列表
    queryLabelData() {
      let params = {
        token: this.$store.state.token,
        page: this.lapage,
        limit: this.limit
      }
      queryLabels(params).then((rec) => {
        this.lapage = rec.data.current_page
        this.latotalCount = rec.data.total
        this.labelData = rec.data.data
      });
    },
    //修改或新增
    editlabel(row) {
      console.log(row)
      this.label_show = true
      if (row != undefined) {
        this.labelForm = { ...row }
        this.label_title = '修改标签'
      } else {
        //新增
        this.labelForm.name = ''
        this.labelForm.id = undefined
        this.label_title = '新增标签'
      }
    },
    //删除标签
    delLabel(id) {
      this.$confirm("此操作将删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          token: this.$store.state.token,
          id: id
        }
        labelDelete(params).then((rec) => {
          // console.log(rec);
          if (rec.code == 200) {
            this.$message({
              showClose: true,
              message: rec.message,
              type: "success",
            });
            this.queryLabelData()
          }
        });
      });
    },
    //添加状态
    publicStatus(row) {
      // console.log('状态添加', row.role_type)
      if (row.id === undefined) {
        //新增
        this.statusForm = {
          number: "",
          name: "",
          rgb: "",
          colorName: "",
          role_type: ""
        }
        this.isModify = true
        this.isDisabled = false
      } else if (row.id != undefined) {
        //修改,对象拷贝
        this.statusForm = Object.assign({}, row);
        this.isModify = false
        this.isDisabled = true
      }
      this.statusDialogVisible = true
    },
    //修改状态
    statusModifyData() {
      // console.log('打印对象', this.statusForm.id)
      let params = {
        name: this.statusForm.name,
        number: this.statusForm.number,
        role_type: this.statusForm.role_type,
        rgb: this.statusForm.rgb,
        token: this.token,
        id: this.statusForm.id
      }
      statusEditData(params).then((res) => {
        this.$message.success("修改状态成功");
        this.statusDialogVisible = false
        this.queryStatusList()
      });
    },
    //删除状态
    delStatus(row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id,
          token: this.token,
        }
        statusDelData(params).then((res) => {
          this.$message.success("删除成功");
          this.statusDialogVisible = false
          this.queryStatusList()
        });
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    //取消状态值
    canCel() {
      this.statusForm = {
        number: "",
        name: "",
        rgb: "",
        colorName: ""
      }
      this.statusDialogVisible = false
    },
    //保存状态
    saveStatus() {
      if (this.isModify) {
        //新增
        if (this.statusForm.name == '' || this.statusForm.number == '' || this.statusForm.rgb == '') {
          this.$message.warning("表单信息不能为空");
          return false
        }
        let params = {
          name: this.statusForm.name,
          number: this.statusForm.number,
          role_type: this.statusForm.role_type,
          rgb: this.statusForm.rgb,
          token: this.token
        }
        statusAddList(params).then((res) => {
          console.log(res, '返回的状态打印')
          this.$message.success("添加状态成功");
          this.statusDialogVisible = false
          this.queryStatusList()
        });
      } else if (!this.isModify) {
        //修改
        this.statusModifyData()
      }
    },
    //保存状态
    statusConfirm() {
      if (this.timeRange.length == 0) {
        this.$message({
          type: "warning",
          message: "开始和结束时间不能为空",
        });
        return false
      }
      let stateVal = 1
      if (this.statuslabel === '开放申报') {
        stateVal = 1
      } else if (this.statuslabel === '未开放申报') {
        stateVal = 2
      }
      // console.log('打印时间数组', this.timeRange)
      let param = {
        start_time: this.timeRange.length > 0 ? this.timeRange[0] : '',
        end_time: this.timeRange.length > 0 ? this.timeRange[1] : '',
        status: stateVal,
        token: this.$store.state.token,
        type: 2
      }
      standardTimeStore(param).then((res) => {
        // console.log(res, '打印返回的数据')
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
        }
      });
    },
    //取消状态
    clearTimer() {
      if (this.statuslabel == "开放申报") {
        this.timeRange = []
      }
    },
    //获取管理员用户列表
    getUserList(cl, ty) {
      let param = {};
      if (ty == 1) {
        param = {
          token: this.token,
          page: this.page,
          limit: 10,
          class: cl,
          type: ty,
        };
      } else {
        param = {
          token: this.token,
          page: this.userpage,
          limit: 10,
          class: cl,
          type: ty,
        };
      }
      if (this.name) {
        param.name = this.name;
      }
      if (this.telephone) {
        param.telephone = this.telephone;
      }
      if (ty == 1) {
        getUserList(param).then((res) => {
          // console.log('列表数据',res)
          this.userstabledata = res.data;
          this.limit = Number(res.limit)
          this.page = res.page;
          this.totalCount = res.totalCount;
          this.totalPage = res.totalPage;
        });
      } else {
        getUserList(param).then((res) => {
          this.userstabledata = res.data;
          this.userlimit = Number(res.limit)
          this.userpage = res.page;
          this.usertotalCount = res.totalCount;
          this.usertotalPage = res.totalPage;
        });
      }
    },


    //搜索
    search() {
      let type = 1
      if (this.activeName == "first") {
        type = 1
      } else {
        type = 3
      }
      this.page = 1;
      this.getUserList(4, type);
    },
    //清空
    qingkong() {
      this.name = ""
      this.telephone = ""
      this.address = ""
      let type = 1
      if (this.activeName == "first") {
        type = 1
      } else {
        type = 3
      }
      this.getUserList(4, type);
    },

    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getUserList(4, 1);
    },
    userhandleSizeChange(page) {
      this.userlimit = page;
      this.getUserList(4, 3);
    },

    handleCurrentChange(page) {
      this.page = page;
      this.getUserList(4, 1);
    },
    userhandleCurrentChange(page) {
      this.userpage = page;
      this.getUserList(4, 3);
    },

    gotoStep(row) {
      let url = "/user/accontimInfo?id=" + row.id + "&token=" + row.token;
      return url;
    },
    //状态管理的分页
    userhandleStatus(page) {
      this.statuslimit = page
      this.queryStatusList()
    },
    statusCurrentChange(page) {
      this.statuspage = page;
      this.queryStatusList()
    },
    queryStatusList() {
      let params = {
        token: this.token,
        page: this.statuspage,
        limit: this.statuslimit
      }
      statusList(params).then((res) => {
        // console.log(res, '打印返回的数据')
        this.statusTable = res.data.data
        this.statuspage = res.data.current_page
        this.statustotal = res.data.total
      });
    }
  },
};

</script>
<style scoped>
/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}

/deep/.el-input__suffix {
  right: 43px;
  transition: all 0.3s;
  border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
  min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}

/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2d2d2d;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 16px;
}

/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

.el-input__inner {
  position: relative;
}

.el-color-picker {
  position: absolute;
  right: 0;
}

.pitable {
  /*height: 60vh;*/
}

.litop {
  margin-top: 20px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 38px 0;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.linkto {
  text-decoration: none;
  color: #3082e2;
}

.linkto>span:hover {
  color: #84b5ec;
}

/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  /* padding: 1px 15px; */
  margin: 20px 0 10px 0;
}

.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #eeeeee;
}

.surebtn {
  margin-left: 30px;
}

.delete_text {
  color: #f51a1a;
}

.timearea {
  display: flex;
}

.timearea .current_label {
  padding: 10px 11px;
  font-size: 14px;
}
</style>
